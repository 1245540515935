<template>
  <div>
    <h4>Contact Lists</h4>
    <router-view />
  </div>
</template>

<script>
export default {
  
}
</script>